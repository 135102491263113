<template>
    <div>
      <v-snackbar
        v-model="successSnackbar"
        :timeout="4000"
        top
        transition="scroll-y-transition"
        color="success"
      >
        Планирование успешно создана!
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="successSnackbar = false"
          >
            ОК
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="errorSnackbar"
        top
        :timeout="-1"
        color="error"
      >
        Ошибка ({{ result }}) обратитесь к администратору!!!
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="errorSnackbar = false"
          >
            ОК
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        color="error"
        v-model="isValidationError"
        top
        right
      >
        Ошибка валидации формы, проверьте поля
      </v-snackbar>
      <div class="wrapper-form">
        <form @submit.prevent>
          <form-section-title title="Сотрудники"/>
          <div class="wrapper-block">
            <UsersInput
              v-model="users"
              label="Планируемые сотрудники"
              ref="users"
              :error-messages="usersErrors"
            />

            <v-row>
              <v-col
                class="my-0 py-0"
                xs="12"
                md="6"
              >
                <v-menu
                  v-model="menu1"
                  ref="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  :return-value.sync="planningDates"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="planningDateRangeText"
                      label="Даты планирования"
                      ref="planningDateRangeText"
                      prepend-icon="mdi-calendar"
                      outlined
                      dense
                      :error-messages="planningDateRangeTextErrors"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="planningDates"
                    range
                    locale="ru"
                    selected-items-text='период'
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu1 = false"
                    >
                      Отмена
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(planningDates)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
          <v-col
            class="my-0 py-0"
            xs="12"
            md="6"
          >
            <v-select
              v-model="planningType"
              :items="planningTypeItems"
              label="Тип планирования"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

            <v-radio-group
            v-model="whatCompanySelect"
            column
            mandatory
          >
            <template v-slot:label>
              Компания
            </template>
            <v-radio
              v-for="n in whatCompanyItems"
              :key="n.value"
              :label="n.label"
              :value="n.value"
            >
            </v-radio>
            </v-radio-group>

            <v-combobox
            :value="contactInfo"
              :items="contactInfoItems"
              label="Контактная информация"
              append-icon=""
              outlined
              dense
              ref="contactInfo"
              :error-messages="contactInfoErrors"
              @blur="$v.contactInfo.$touch()"
              @update:search-input="(text) => contactInfo = text"
            ></v-combobox>

            <v-textarea
              v-model="description"
              label="Планируемые работы"
              outlined
              dense
              rows="3"
              auto-grow
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"
            ></v-textarea>

            <v-file-input
            v-model="files"
            multiple
            outlined
            dense
            id="test_file_input"
            label="Добавить файлы (опционально)"
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          </div>
          <form-section-title title="Дополнительная информация"/>
          <div class="wrapper-block">
            <deal-input-sync
              v-model="deal"
              :errorMessages="dealErrors"
            />
          </div>
          <v-btn
            class="mr-4 mb-4"
            color="primary"
            @click="submit"
            type="submit"
            :loading="loading"
          >
            Запланировать
          </v-btn>
        </form>
      </div>
    </div>
  </template>
  <script>
  import { required } from 'vuelidate/lib/validators'
  import { mapActions } from 'vuex'
  import UsersInput from './app/UsersInput'
  import FormSectionTitle from '@/components/FormSectionTitle'
  import DealInputSync from '@/components/DealInputSync'

  export default {
    name: 'PlanningToScheduleForm',
    validations: {
      users: { required },
      planningDateRangeText: { required },
      description: { required },
      contactInfo: { required },
      deal: { required }
    },
    components: {
      DealInputSync,
      FormSectionTitle,
      UsersInput
    },
    data: () => ({
      users: [],
      planningDates: [],
      planningType: 221,
      planningTypeItems: [
        {
          value: 221,
          text: 'Реализация проекта'
        },
        {
          value: 223,
          text: 'Выездной ремонт'
        },
        {
          value: 245,
          text: 'Работы в офисе'
        },
        {
          value: 935,
          text: 'Обучение'
        },
        {
          value: 845,
          text: 'Прочее'
        }
      ],
      whatCompanySelect: 1213,
      whatCompanyItems: [
        {
            value: 1213,
            label: 'PolyService'
        },
        {
            value: 1215,
            label: 'Polymedia'
        }
      ],
      contactInfo: '',
      contactInfoItems: [],
      files: [],
      description: '',
      deal: null,

      menu1: false,

      result: '',
      loading: false,
      successSnackbar: false,
      errorSnackbar: false,
      isValidationError: false
    }),
    computed: {
      planningDateRangeText() {
        if (this.planningDates[1]) {
          let dates = this.planningDates
          if (dates[0] > dates[1]) {
            dates = dates.reverse()
          }
          return `c ${dates[0].split('-').reverse().join('-')} по ${dates[1].split('-').reverse().join('-')}`
        }
        if (this.planningDates[0]) {
          return this.planningDates[0].split('-').reverse().join('-')
        }
        return []
      },
      planningDateRangeTextErrors() {
        const errors = []
        if (!this.$v.planningDateRangeText.$dirty) return errors
        !this.$v.planningDateRangeText.required && errors.push('Укажите даты командировки')
        return errors
      },
      usersErrors() {
        const errors = []
        if (!this.$v.users.$dirty) return errors
        !this.$v.users.required && errors.push('Укажите командируемых сотрудников')
        return errors
      },
      contactInfoErrors() {
        const errors = []
        if (!this.$v.contactInfo.$dirty) return errors
        !this.$v.contactInfo.required && errors.push('Укажите контактную информацию')
        return errors
      },
      descriptionErrors() {
        const errors = []
        if (!this.$v.description.$dirty) return errors
        !this.$v.description.required && errors.push('Укажите планируемые работы')
        return errors
      },
      dealErrors() {
        const errors = []
        if (!this.$v.deal.$dirty) return errors
        !this.$v.deal.required && errors.push('Укажите сделку')
        return errors
      }
    },
    methods: {
      ...mapActions(['addPlanning']),
      async submit() {
        try {
          this.$v.$touch()
        if (this.$v.$invalid) {
          this.isValidationError = true

          return false
        } else {
          this.$v.$reset()
          this.loading = true

          const formData = {
            users: this.users.map(user => user.id),
            planningDates: this.planningDates,
            planningType: this.planningType,
            company: this.whatCompanySelect,
            contactInfo: this.contactInfo,
            description: this.description,
            files: this.files,
            deal: this.deal
          }
          const result = await this.addPlanning(formData)
          this.loading = false
          this.result = result

          if (result.elementId) {
            this.successSnackbar = true
            this.description = this.contactInfo = ''
            this.planningDates = []
            this.files = []
            this.users = []
          } else {
            this.errorSnackbar = true
          }
        }
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
  </script>

  <style scoped>

  </style>
